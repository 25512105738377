import { Component, Inject, OnInit, ChangeDetectorRef, ViewChild, AfterViewInit, OnDestroy, ChangeDetectionStrategy, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, Subscription, merge, from, Subject, BehaviorSubject, firstValueFrom, lastValueFrom } from 'rxjs';
import { AppState } from 'src/app/app.states';
import { tap, map, filter, catchError, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { locationToAddress, zoom, zoomScrollable, closeZoom, isNullOrWhitespace } from 'src/app/common/functions';

import { Globals } from 'src/app/common/globals';
import { Router } from '@angular/router';
import { LocationsMapDialogComponent } from '../../common/locations-map/locations-map-dialog.component';
import { Geolocation } from 'src/app/common/model/geolocation';
import { availableCols, getVisibleColsList, TaskViewType } from '../../tasks/task-common';

import icClose from '@iconify/icons-ic/twotone-close';
import icSearch from '@iconify/icons-ic/twotone-search';
import roundViewColumn from '@iconify/icons-ic/round-view-column';
import twotoneEmail from '@iconify/icons-ic/twotone-email';
import twotonePhoneForwarded from '@iconify/icons-ic/twotone-phone-forwarded';
import twotonePersonPin from '@iconify/icons-ic/twotone-person-pin';
import twotoneInsertDriveFile from '@iconify/icons-ic/twotone-insert-drive-file';
import twotoneFolder from '@iconify/icons-ic/twotone-folder';
import roundSortByAlpha from '@iconify/icons-ic/round-sort-by-alpha';
import icFilterList from '@iconify/icons-ic/twotone-filter-list';
import twotoneDriveFolderUpload from '@iconify/icons-ic/twotone-drive-folder-upload';
import outlineGridOn from '@iconify/icons-ic/outline-grid-on';
import twotoneListAlt from '@iconify/icons-ic/twotone-list-alt';
import baselinePlus from '@iconify/icons-ic/baseline-plus';
import twotoneBuildCircle from '@iconify/icons-ic/twotone-build-circle';
import twotoneSpeakerNotes from '@iconify/icons-ic/twotone-speaker-notes';

// PBI
import * as pbi from 'powerbi-client';
import { loadPbiTokenAction } from 'src/app/api/pbi/pbi.actions';

// CALENDAR
import theme from 'src/@vex/utils/tailwindcss';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { TaskDetailComponent } from '../../tasks/task-detail/task-detail.component';
import { dialogMyTaskClosedAction } from 'src/app/api/tasks/my-tasks/my-tasks.actions';
import { TaskFilterComponent } from 'src/@vex/components/task-filter/task-filter.component';
import { PopoverService } from 'src/@vex/components/popover/popover.service';
import { TaskSortComponent } from 'src/@vex/components/task-sort/task-sort.component';
import { UserStateIFace } from 'src/app/api/user/user.state';
import { TaskListSortModel } from '../../tasks/task-list-sort/task-list-sort.model';
import { TaskAgenda } from 'src/app/api/tasks/my-tasks/my-tasks.state';
import { TasksTableColumnsComponent } from '../../tasks/tasks-table-columns/tasks-table-columns.component';
import { TaskNewComponent } from '../../tasks/task-new/task-new.component';
import { TaskRepairComponent } from '../../tasks/task-repair/task-repair.component';
import { MessageRef } from 'src/@vex/components/messages/message-list/message-list';
import { MessageListComponent } from 'src/@vex/components/messages/message-list/message-list.component';
import { PopoverTableService } from 'src/@vex/components/popover-table/popover-table.service';
import { ConfigService } from 'src/@vex/services/config.service';
import { TasksService } from 'src/app/services/tasks.service';
import { TaskListSortComponent } from '../../tasks/task-list-sort/task-list-sort.component';
import { TasksTableComponent } from '../../tasks/tasks-table/tasks-table.component';
import { TasksListComponent } from '../../tasks/tasks-list/tasks-list.component';

const colors: any = {
  blue: {
    primary: theme.colors.primary['500'],
    secondary: theme.textColor['primary-contrast']['500']
  },
  yellow: {
    primary: theme.colors.amber['500'],
    secondary: '#FDF1BA'
  },
  red: {
    primary: theme.colors.red['500'],
    secondary: 'white'
  },
  shadow: {
    primary: 'white',
    secondary: 'var(--foreground-divider)'
  }
};



const TS = Honeycomb.Common.Enums.TaskState;

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'my-store-detail',
  templateUrl: './my-store-detail.component.html',
  styleUrls: ['./my-store-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyStoreDetailComponent implements OnInit, AfterViewInit, OnDestroy {

  icClose = icClose;
  icSearch = icSearch;
  roundViewColumn = roundViewColumn;
  twotoneEmail = twotoneEmail;
  twotonePhoneForwarded = twotonePhoneForwarded;
  twotonePersonPin = twotonePersonPin;
  twotoneInsertDriveFile = twotoneInsertDriveFile;
  twotoneFolder = twotoneFolder;
  roundSortByAlpha = roundSortByAlpha;
  icFilterList = icFilterList;
  twotoneDriveFolderUpload = twotoneDriveFolderUpload;
  outlineGridOn = outlineGridOn;
  twotoneListAlt = twotoneListAlt;
  baselinePlus = baselinePlus;
  twotoneBuildCircle = twotoneBuildCircle;
  twotoneSpeakerNotes = twotoneSpeakerNotes;

  public messageRef = MessageRef;
  @ViewChild('messageList', { static: false }) messageList: MessageListComponent;

  @ViewChild('tasksTable', { static: false }) tasksTable: TasksTableComponent;

  @ViewChild('tasksList', { static: false }) tasksList: TasksListComponent;

  myStoreReport$: Observable<any>;
  pbiToken$: Observable<Honeycomb.Tenant.Reports.IService.Model.PBI.AAD>;
  pbiToken: Honeycomb.Tenant.Reports.IService.Model.PBI.AAD;
  detailReportLoaded = false;

  detailLoading$: Observable<boolean> = of(true);

  // Tasks
  loading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  initialized: Observable<boolean> = of(false);
  viewSort: Honeycomb.Tenant.Tasker.IService.Model.TaskPageSort;

  // pageIndex$: Observable<Map<string, number>>;
  // pageSize$: Observable<Map<string, number>>;

  model$: Observable<Honeycomb.Tenant.LookupTables.IService.Model.Location> = of(null);
  model: Honeycomb.Tenant.LookupTables.IService.Model.Location = null;
  openings: Array<Honeycomb.Tenant.LookupTables.IService.Model.LocationAttribute> = [];
  statuses$: Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.TaskStatus>> = of([]);
  statusesAll$: Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.TaskStatus>>;

  planograms: Array<Honeycomb.Tenant.LookupTables.IService.Model.LocationImage> = [];
  attachments: Array<Honeycomb.Tenant.LookupTables.IService.Model.LocationImage> = [];

  contacts = [];
  events = [];
  tasks = [];
  deliveryDays = [];
  delivaryDaysPadding = '';
  tenantHash = '';
  messagesTotal = 0;
  messagesRead = 0;
  messages = [];
  messagesLoading = false;
  storeImage = null;
  agenda = TaskAgenda.MyStoreDetailTasks;

  tablePageIndex = 0;
  tablePageSize = 5;

  listPageIndex = 0;
  listPageSize = 5;

  files: Honeycomb.Tenant.Tasker.IService.Model.TaskFileInfo[] = [];

  taskTypeDefaults = Object.values(Honeycomb.Common.Enums.TaskType)
    .filter(e => !isNaN(e as any))
    .filter(e => e !== Honeycomb.Common.Enums.TaskType.unknown && e !== Honeycomb.Common.Enums.TaskType.repairRequest);

  // tasks
  storeTasksFilter = {
    taskTypes: this.taskTypeDefaults
  } as Honeycomb.Tenant.Tasker.IService.TaskListFilter;
  storeTasks = new BehaviorSubject([]);
  storeTasksLoading = true;

  subscriptions: Array<Subscription> = [];

  attechmentDirPath: string[] = [];
  filterOpen = false;
  sortOpen = false;
  contractValidity = null;
  openingDate = null;

  uiroles: string[] = [];
  private roleDebug = [];

  // CALENDAR
  calendarTasks = [];
  calendarFilter = {
    baseTaskStatuses: [TS.inProgress, TS.new, TS.unknown] // taskStatusIDs
  } as Honeycomb.Tenant.Tasker.IService.TaskListFilter;
  calendarTasksLoading = true;
  calendarSelectedDate: Date = new Date();

  private powerbi: pbi.service.Service;
  reportName = '';

  // TASK
  searchCtrl = new FormControl();
  taskViewType = TaskViewType;
  viewType: TaskViewType = TaskViewType.Rows;
  selectedJobID: number = null;
  tasksAgenda = TaskAgenda.MyStoreDetailTasks;

  filteredArray = [];
  defaultRecords = [];
  attechmentDirPathStr = () => this.attechmentDirPath.join('\\');
  attechmentDirPathVisible = () => { const a = [...this.attechmentDirPath]; a.splice(0, 1); return a.join(' / '); };

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  // tslint:disable-next-line: max-line-length
              @Inject('LookupLocationController') private taskerLocationController: Honeycomb.Tenant.LookupTables.IService.LocationsController,
              @Inject('FileController') private fileController: Honeycomb.Tenant.Tasker.IService.Controller.FileController,
              @Inject('TaskController') private taskController: Honeycomb.Tenant.Tasker.IService.Controller.TaskController,
              @Inject('PBIController') private pbiController: Honeycomb.Tenant.Reports.IService.PBIController,
              @Inject('TaskStatusController') private taskStatusController: Honeycomb.Tenant.Tasker.IService.Controller.TaskStatusController,
              private popover: PopoverService,
              private popoverTable: PopoverTableService,
              private dialog: MatDialog,
              private store: Store<AppState>,
              private cd: ChangeDetectorRef,
              private globals: Globals,
              private config: ConfigService,
              private trans: TranslateService,
              private tasksService: TasksService,
              private router: Router) {

  this.loading$ = this.tasksService.loading;
  this.viewSort = this.tasksService.getSort();

  this.powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
  const hashSubs = this.store.select(s => s.auth.tenantHash).subscribe(s => this.tenantHash = s);

  store.pipe(select(x => x.user)).
      subscribe(u => {
        this.uiroles = u.uiroles;
        this.selectedJobID = u.selectedJobID;
      });

  store.pipe(select(x => x.user)).subscribe(u => this.uiroles = u.uiroles);

  this.subscriptions.push(hashSubs);

  const userSub = this.store.pipe(select(s => s.user)).subscribe(u => {
    if (!!u && !!u.currentUser && !!u.currentUser.contactRoles) {
      const selectedJob = u.currentUser.userJobs.find(j => j.jobID === u.selectedJobID);
      if (selectedJob) {
        this.calendarFilter.jobID = this.storeTasksFilter.jobID = u.selectedJobID;
      }
    }
  });

  this.subscriptions.push(userSub);

  // const pageSizeSub = this.pageSize$.subscribe(pi =>  {
  //   let newTablePageSize = pi.get('tasks-main-table-' + this.agenda) || 5;
  //   if (newTablePageSize !== this.tablePageSize) {
  //     this.tablePageSize = pi.get('tasks-main-table-' + this.agenda) || 5;
  //   }
  //   let newListPageSize = pi.get('tasks-main-list-' + this.agenda) || 5;
  //   if (newListPageSize !== this.listPageSize) {
  //     this.listPageSize = pi.get('tasks-main-list-' + this.agenda) || 5;
  //   }
  // });

  //this.subscriptions.push(pageSizeSub);

  this.router.events.pipe(tap(_ => {
    closeZoom();
  })).toPromise();

  this.searchCtrl.valueChanges.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    switchMap(async (v, i) => { 
      console.log('inner switchmap', v, i);
      await this.onFilterChange(v, i); ;
    })
  ).subscribe();

  /* PBI */
  this.myStoreReport$ = store.pipe(
    filter(x => !x.user.loading && !!x.user.selectedJobID && !!x.user.currentUser),
    select(x => x.user),
    tap((u: UserStateIFace) => {
      const userJob = u.currentUser.userJobs.find(uj => uj.jobID === u.selectedJobID);
      if (!userJob) {
        return;
      }
      const selectedRoleID = userJob.job.roleID;
      const contactRole = u.currentUser.contactRoles.find(cr => cr.roleID === selectedRoleID);
      this.reportName = contactRole.attributes['tasker-my-store-report'];
      if (!isNullOrWhitespace(this.reportName)) { // If any PBI exists
        this.store.dispatch(loadPbiTokenAction()); // Ask for data for tiles
      }
    })
  );
  this.myStoreReport$.subscribe(_ => null);

  this.pbiToken$ = store.pipe(
    filter(x => !!x.pbi && !!x.pbi.aad),
    select(x => x.pbi),
    map(p => p.aad)
  );
  const pbiTokenSubscription = this.pbiToken$.subscribe(token => {
    if (this.pbiToken == null
      // || token.access_token !== this.pbiToken.access_token
    ) { // Hack, called twice for unknown reason
      this.pbiToken = token;
    }
  });
  this.subscriptions.push(pbiTokenSubscription);

  this.statusesAll$ = this.taskStatusController.List(null, this.selectedJobID);
  var preselectedStatusesSub = this.statusesAll$.pipe(
    map(p => 
     p.filter(s => !!s.defaultFilter)
     .map(a => a.taskStatusID)))
     .subscribe(s => { 
       this.storeTasksFilter.taskStatusIDs = s;
     });

  this.subscriptions.push(preselectedStatusesSub);

  this.tasksService.setPager(this.agendaKey, 0, this.listPageSize);
}

  address(loc: Honeycomb.Tenant.LookupTables.IService.Model.Location): string {
    if (!loc) {
      return '';
    }
    return locationToAddress(loc);
  }

  public get agendaKey() {
    if (this.viewType === this.taskViewType.Table) {
      return 'my-store-table-' + this.data.locationID;
    }
    if (this.viewType === this.taskViewType.Rows) {
      return 'my-store-list-' + this.data.locationID;
    }
    return 'my-store-list';
  }

  navigate(address: string) {
    // If it's an iPhone..
    if ((navigator.platform.indexOf('iPhone') !== -1)
      || (navigator.platform.indexOf('iPod') !== -1)
      || (navigator.platform.indexOf('iPad') !== -1))
      window.open(`http://maps.apple.com/?daddr=${address}&dirflg=d&t=h`);
    else
      window.open(`https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${address}`);
  }

  async ngOnInit() {
    this.model = await lastValueFrom(this.taskerLocationController.Detail(this.data.locationID));

    this.calendarFilter.locationID = this.storeTasksFilter.locationID = this.model.locationID;

    this.detailLoading$ = of(false);
    this.planograms = this.model.locationImages.filter(li => li.fileGroup === 'Planogram');
    const storePhotos = this.model.locationImages.filter(li => li.fileGroup === 'ShopPhoto');

    if (storePhotos.length > 0) {
      this.storeImage = storePhotos.sort((a, b) => (b.isPrimary ? 1 : 0) - (a.isPrimary ? 1 : 0))[0];
    }

    const openingHours = this.model.attributes.find(a => a.locationAttributeType.name === 'OpeningHours');
    if (!!openingHours) { this.openings.push(openingHours); }
    const openingAtSaturday = this.model.attributes.find(a => a.locationAttributeType.name === 'OpeningAtSaturday');
    if (!!openingAtSaturday) { this.openings.push(openingAtSaturday); }
    const openingAtSunday = this.model.attributes.find(a => a.locationAttributeType.name === 'OpeningAtSunday');
    if (!!openingAtSunday) { this.openings.push(openingAtSunday); }

    this.contractValidity = this.model.attributes.find(a => a.locationAttributeType.name === 'ContractValidity');

    this.openingDate = this.model.attributes.find(a => a.locationAttributeType.name === 'OpeningDate');

    const locationContacts = this.model.attributes.find(a => a.locationAttributeType.name === 'LocationContacts');

    if (!!locationContacts && locationContacts.value.length > 0) {
      this.contacts = JSON.parse(locationContacts.value);
      /*
      [{
          "name": "Karel Ostrý - Regionální Manager",
          "email": "karel.ostry@noze.cz",
          "phone": "111222333",
          "address": ""
        }
      ]*/
    }

    const delDays = this.model.attributes.find(a => a.locationAttributeType.name === 'DeliveryDays');

    if (delDays && delDays.value.length > 0) {
      this.deliveryDays = JSON.parse(delDays.value);
      if (this.deliveryDays.length > 3) {
        this.delivaryDaysPadding =  (this.deliveryDays.length - 2) * 10 + 'px';
      } else {
        this.delivaryDaysPadding = '5px';
      }
    }

    this.attechmentDirPath = [this.model.locationNumber.padStart(3, '0')];
    await this.loadFiles();

    const sub = this.tasksService.data.subscribe(async r => {
      console.log('tasks-loaded', r.length);
      this.storeTasks.next(r);

      setTimeout(async () => {
        // this.paginator._intl.getRangeLabel = this.paginatorRangeLabel;
        if (!!this.tasksTable) {
          await this.tasksTable.forcePagerUpdate();
        }
    
        if (!!this.tasksList) {
          await this.tasksList.forcePagerUpdate();
        }
      }, 0);
    });

    this.subscriptions.push(sub);

    this.tasksService.setPager(this.agendaKey, 0, this.listPageSize);

    this.cd.detectChanges();
  }

  async ngAfterViewInit(): Promise<void> {
    if (!!this.tasksTable) {
      await this.tasksTable.forcePagerUpdate();
    }

    if (!!this.tasksList) {
      await this.tasksList.forcePagerUpdate();
    }

    this.tasksService.setPager(this.agendaKey, 0, this.listPageSize);

    this.cd.detectChanges();
  }

  async loadFiles() {
    this.files = await this.fileController.DirectoryStructure(this.attechmentDirPathStr(), '')
                           .pipe(catchError(e => of([])))
                           .pipe(map(f => f.sort((a, b) => {
                             if (isNaN(Number(a.name)) || isNaN(Number(b.name))) {
                              return 0;
                             }
                             // B-03372 CRS - Moje Prodejny - Přílohy - Řazení adresářu a souborů musí být od nejnovějšího
                             return Number(b.name) - Number(a.name);
                           })))
                           .toPromise();
  }

  async onFilterChange(value: string, i: number) {
    value = value.trim();
    value = value.toLowerCase();
    this.storeTasksFilter.fulltext = value;
    if (!this.storeTasksFilter.sortParams) {
      this.storeTasksFilter.sortParams = TaskListSortModel.sortItemsDefault;
    }
    this.tasksService.setPager('tasks-main-list-' + this.agenda, 0, this.tablePageSize);
    this.tasksService.setPager('tasks-main-table-' + this.agenda, 0, this.tablePageSize);
    await this.reloadTasks();
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getImageUrl(imgGuid: string, maxWidth: number = 80, maxHeight: number = 80): string {
    if (imgGuid === null) { return null; }
    return [this.globals.GetUrlPrefix(), 'api/DocumentStorage/record', imgGuid].join('/')
      + '?TenantHash=' + this.tenantHash + '&maxWidth=' + maxWidth + '&maxHeight=' + maxHeight;;
  }

  zoomImage(imgGuid: string) {
    const imageUrl = [this.globals.GetUrlPrefix(), 'api/DocumentStorage/record', imgGuid].join('/')
      + '?TenantHash=' + this.tenantHash;

    zoom(imageUrl, this.trans.instant('tasker.task-activities.image-close-btn'));
  }

  zoomScrollable(imgGuid: string) {
    const imageUrl = [this.globals.GetUrlPrefix(), 'api/DocumentStorage/record', imgGuid].join('/')
      + '?TenantHash=' + this.tenantHash;

    zoomScrollable(imageUrl, this.trans.instant('tasker.task-activities.image-close-btn'));
  }


  searchFocus($event: FocusEvent) {
    const appBar = ($event.target as HTMLElement).closest('.bg-app-bar');
    appBar.classList.add('input-focused');
  }

  searchBlur($event: FocusEvent) {
    const appBar = ($event.target as HTMLElement).closest('.bg-app-bar');
    appBar.classList.remove('input-focused');
  }

  async openMapDialog() {
    const dialogRef = this.dialog.open(LocationsMapDialogComponent, {
      data: {
        highlightedLocationIDs: [this.data.locationID],
        showOnlyUserRelated: true,
        centerToPosition: {
          latitude: this.model.latitude,
          longitude: this.model.longitude
        } as Geolocation.GeolocationCoordinates
      }
    });
    await dialogRef.afterClosed().toPromise();
  }

  uiRole(suffix: string, isGlobal?: boolean) {
    let reqUiRole = `tasker.${this.data.permission}.${suffix}`;

    if (!!isGlobal) {
      reqUiRole = suffix;
    }

    if (this.roleDebug.findIndex(r => r === reqUiRole) === -1) {
      this.globals.DebugOut('my-store-detail', reqUiRole);
      this.roleDebug.push(reqUiRole);
    }

    return reqUiRole;
  }

  hasPermission(suffix: string) {
    return this.uiroles.indexOf(this.uiRole(suffix)) > -1;
  }


  // FILES
  public async processFile(file: Honeycomb.Tenant.Tasker.IService.Model.TaskFileInfo) {
    if (file.isFile) {
      await this.downloadFile(file);
      return;
    } else {
      this.attechmentDirPath = file.path; //.join('/');
      // this.files = await this.fileController.DirectoryStructure(this.attechmentDirPathStr(), '').toPromise();
      await this.loadFiles();
    }

    if (file.path.length > 1) {
      const parentPath = [...file.path];
      parentPath.pop();
      this.files.splice(0, 0,
          {
            relativePath: '/..',
            parentPath: '..',
            path: parentPath,
            name: '..',
            isFile: false,
            created: null,
            fileAuthToken: null,
            isExpanded: false,
            children: [],
            hasChildren: false
          });
    }
    this.cd.detectChanges();
  }

  private async downloadFile(file: Honeycomb.Tenant.Tasker.IService.Model.TaskFileInfo) {
    const downloadToken = await this.fileController.FileDownloadToken(encodeURIComponent(file.path.join('\\'))).toPromise();
    const link = document.createElement('a');
    link.setAttribute('download', '');
    link.setAttribute('target', '_blank');
    // tslint:disable-next-line: max-line-length
    link.href = `${this.globals.GetUrlPrefix()}/api/TenantTasker/file/download/${downloadToken}/${file.name}?TenantHash=${this.tenantHash}`;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  public async downloadTaskFile(file: Honeycomb.Tenant.LookupTables.IService.Model.LocationImage) {
    const link = document.createElement('a');
    link.setAttribute('download', '');
    link.setAttribute('target', '_blank');
    // tslint:disable-next-line: max-line-length
    link.href = `${this.globals.GetUrlPrefix()}/api/DocumentStorage/Record/${file.recordUID}?TenantHash=${this.tenantHash}`;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  setPbiElements(token: Honeycomb.Tenant.Reports.IService.Model.PBI.AAD) {
    if (!isNullOrWhitespace(this.reportName)) {
        const report = this.pbiController.GetReport(this.reportName).subscribe(r => {
          const element = document.querySelector('[data-report-stats-name="' + this.reportName + '"]') as HTMLElement;
          if (element && !this.detailReportLoaded) {
            element.removeAttribute('powerbi-access-token');
            this.powerbi.reset(element);
            const embed = (this.powerbi as any).embedNew(element, this.getPBIConfig(r, token));
            embed.reload();
            embed.on('loaded', e => {
              this.detailReportLoaded = true;
              // resolves: target window is not provided.  You must either provide the target window explicitly as argument to request, or specify default target window when constructing instance of this class.
              if (embed && embed.iframe && embed.iframe.contentWindow) {
                const bookmarks = {};
                (embed as any).bookmarksManager.getBookmarks().then(
                  (bm: any[]) => {
                    bm.forEach(b => {
                      bookmarks[b.name] = b.displayName;
                    });
                  },
                  (f: any) => console.error(f)
                );
                embed.on('buttonClicked', (event) => {
                  const bm = bookmarks[(event.detail as any).bookmark];
                  this.router.navigate(['/reporting/detail', bm]);
                });
              }
            });
          }
        });
    }
  }


  private getPBIConfig(
    report: Honeycomb.Tenant.Reports.IService.Model.PBI.PBIReport,
    token: Honeycomb.Tenant.Reports.IService.Model.PBI.AAD) {
    return {
      type: 'report',
      id: report.reportID,
      viewMode: pbi.models.ViewMode.View,
      tokenType: pbi.models.TokenType.Aad,
      permissions: pbi.models.Permissions.All,
      pageView: 'oneColumn',
      filters: this.getFilter(),
      accessToken: token.access_token,
      embedUrl: report.report.embedUrl + '&language=' + this.config.getLanguage(), // cs for now
      settings: {
        navContentPaneEnabled: false,
        filterPaneEnabled: false,
        layoutType: pbi.models.LayoutType.Custom,
        hyperlinkClickBehavior: pbi.models.HyperlinkClickBehavior.RaiseEvent
      },
      pageName: 'DefaultPage'
    } as pbi.IEmbedConfiguration;
  }

  getFilter(): any[] {

    const locationFilter = {
      filterType: 1,
      target: {
        column: 'LookupLocationID',
        table: 'Filiálky'
      },
      operator: 'In',
      values: [this.model.locationID],
    };
    return [locationFilter];
  }
 
  async tabChanged($event: MatTabChangeEvent) {
    // The only way how to identify tab - index can change, because some tabs can be hidden
    const tabElement = $event.tab.content.viewContainerRef.element.nativeElement;
    if (tabElement.hasAttribute('data-tab-calendar')) {
      await this.reloadCalendarTasks();
    }

    if (tabElement.hasAttribute('data-tab-store-tasks')) {
      await this.reloadTasks();
    }

    if (tabElement.hasAttribute('data-tab-store-bpi')) {
      await this.reloadPbiReport();
    }

    if (tabElement.hasAttribute('data-tab-messages')) {
      await this.messageList.refreshMessages();
    }
  }

  private async reloadCalendarTasks() {
    this.calendarTasksLoading = true;
    this.calendarTasks = await this.taskController.List(this.calendarFilter).toPromise();
    this.calendarTasksLoading = false;
    this.cd.detectChanges();
  }


  public calendarDateSelected($event) {
    this.calendarSelectedDate = $event;
  }

  updateTask(taskID: number) {
    this.dialog.open(TaskDetailComponent, { data: { taskID } }).afterClosed()
        .subscribe(result => {
        if (result && (result.saved || result.forceReload)) {
          this.reloadTasks();
        }
        this.store.dispatch(dialogMyTaskClosedAction());
        if (result && result.redirect) {
          this.updateTask(result.redirect.taskID);
        }
    });
  }

  private async reloadPbiReport() {
    this.setPbiElements(this.pbiToken);
  }

  async toggleTaskView() {
    var pageIndex = this.tasksService.pageIndex(this.agendaKey);
    
    if (this.viewType === TaskViewType.Rows) {
      this.viewType = TaskViewType.Table;
      var pageSize = this.tasksService.pageSize(this.agendaKey);
      if (pageSize < 10) {
        pageSize = 10;  // min row count for table
        pageIndex = 0; // reset, pager changed
      }
      this.tasksService.setPager(this.agendaKey, pageIndex, pageSize);
    } else if (this.viewType === TaskViewType.Table) {
      var pageSize = this.tasksService.pageSize(this.agendaKey);
      this.viewType = TaskViewType.Rows;
      this.tasksService.setPager(this.agendaKey, pageIndex, pageSize);
    }
    await this.reloadTasks();
    localStorage.setItem('tasksViewType', JSON.stringify(this.viewType));
  }

  private async reloadTasks() {
    await this.tasksService.setDefaultAgendaFilter(true, this.agenda, this.selectedJobID);
    var taskFilter = this.tasksService.getFilterRef();

    var pageIndex = this.tasksService.pageIndex(this.agendaKey);
    var pageSize = this.tasksService.pageSize(this.agendaKey);

    taskFilter.pageIndex = pageIndex;
    taskFilter.pageSize = pageSize;

    taskFilter.priorities = this.storeTasksFilter.priorities;
    taskFilter.dateFrom = this.storeTasksFilter.dateFrom;
    taskFilter.dateTo = this.storeTasksFilter.dateTo;
    taskFilter.fulltext = this.storeTasksFilter.fulltext;
    taskFilter.locationID = this.model.locationID;
    taskFilter.taskRead = this.storeTasksFilter.taskRead;
    taskFilter.taskRelationAdditional = this.storeTasksFilter.taskRelationAdditional;
    taskFilter.taskStatusIDs = this.storeTasksFilter.taskStatusIDs;
    taskFilter.operations = this.storeTasksFilter.operations;

    if (!this.selectedJobID) {
      // fixes page reload (and waits for selected job, by default first)
      this.store.pipe(
        select(s => s.user),
        filter(u => !!u && !!u.currentUser && u.currentUser.userJobs.length > 0
                    && !!u.selectedJobID && u.selectedJobID !== undefined
                    && !!u.dashboard && u.dashboard.taskFilters !== undefined),
          distinctUntilChanged((a, b) => a.selectedJobID === b.selectedJobID),
          tap(async (u) => {
            await this.tasksService.setDefaultTaskStatuses(u.selectedJobID);
            await this.getData(taskFilter, u.selectedJobID);
          })).subscribe(_ => null);
    } else {
      await this.getData(taskFilter, this.selectedJobID);
    }

    this.tasksService.forcePagerUpdate.emit();

    this.cd.detectChanges();
  }

  private async getData(locFilter: Honeycomb.Tenant.Tasker.IService.TaskListFilter, selectedJobID: number) {
    this.cd.detectChanges();
    locFilter.fulltext = this.searchCtrl.value
    this.selectedJobID = selectedJobID;   
    locFilter.mainFilter = Honeycomb.Common.Enums.TaskMainFilter.all;
    locFilter.locations = [ this.data.locationID ];
    this.tasksService.setFilter(locFilter);
    await this.tasksService.load(this.agendaKey);
    this.initialized = of(true);
  }

  async calendarTaskUpdated(taskID: number) {
    console.log('task updated, reloading...', taskID);
    await this.reloadCalendarTasks();
  }

  async createTask(isRepair?: boolean) {
    let taskCreated: any = null;

    if (isRepair) {
      taskCreated = await this.dialog.open(TaskRepairComponent, {
        data: {
          agenda: this.tasksAgenda,
          locationID: this.model.locationID
        }
      }).afterClosed().toPromise();
    } else {
      taskCreated = await this.dialog.open(TaskNewComponent, {
        data: {
          agenda: this.tasksAgenda,
          defaults: {
            locationID: this.model.locationID
          }
        }
      }).afterClosed().toPromise();
    }

    if (taskCreated) {
      this.reloadTasks();
    }
  }

  showFilterDialog(originRef: any) {
    this.filterOpen = true;
    this.cd.markForCheck();

    const popoverRef = this.popover.open({
      content: TaskFilterComponent,
      origin: originRef,
      offsetY: 12,
      data: this.storeTasksFilter,
      position: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ]
    });

    popoverRef.afterClosed$.subscribe((pop) => {
      this.filterOpen = false;
      if (!!pop.data) {
        this.storeTasksFilter.dateFrom = pop.data.dateFrom;
        this.storeTasksFilter.dateTo = pop.data.dateTo;
        this.storeTasksFilter.taskRead = pop.data.taskRead;
        this.storeTasksFilter.priorities = pop.data.priorities;
        this.storeTasksFilter.operations = pop.data.operations;
        this.storeTasksFilter.locationID = pop.data.locationID;
        this.storeTasksFilter.locations = pop.data.locations;
        this.storeTasksFilter.taskRead = pop.data.taskRead;
        this.storeTasksFilter.taskRelationAdditional = pop.data.taskRelationAdditional;
        this.reloadTasks();
      }
    });
  }

  async showAllEvents(eventsData: any, $event: any) {
    
    let eventsTitle = await firstValueFrom(this.trans.get('tasker.store-detail.events'));

    this.popoverTable.open(
      { 
        origin: $event.target, 
        data: eventsData,
        title:  eventsTitle,
        position: [
          {
            originX: 'center',
            originY: 'top',
            overlayX: 'center',
            overlayY: 'bottom'
          },
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
          },
        ]
      })
  }

  showColumnsDialog(originRef: any) {
    this.sortOpen = true;
    this.cd.markForCheck();

    const colsData = {
       allColumns: availableCols(),
       visibleColumns: getVisibleColsList(TaskAgenda.MyStoreDetailTasks),
       agenda: TaskAgenda.MyStoreDetailTasks
    } as any;

    const popoverRef = this.popover.open({
      content: TasksTableColumnsComponent,
      origin: originRef,
      offsetY: 12,
      data: colsData,
      position: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ]
    });

    popoverRef.afterClosed$.subscribe(async () => {
      this.filterOpen = false;
      await this.reloadTasks();
      this.cd.markForCheck();
    });
  }

  showSortDialog(originRef: any) {
    this.sortOpen = true;
    this.cd.markForCheck();

    const popoverRef = this.popover.open({
      content: TaskListSortComponent,
      origin: originRef,
      offsetY: 12,
      data: this.storeTasksFilter,
      position: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ]
    });

    popoverRef.afterClosed$.subscribe(async (pop) => {
        this.sortOpen = false;
        this.cd.markForCheck();
        await this.reloadTasks();
    });
  }
}
