<div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
  <img *ngIf="taskForm.get('imageSrc') && taskForm.get('imageSrc').value" [src]="taskForm.get('imageSrc').value" class="avatar mr-5">
  <div fxLayout="column" fxFlex>
    <h2 *ngIf="taskForm.get('name') && taskForm.get('name').value" class="headline m-0" fxFlex="auto">
      {{ taskForm.get('name').value }} <span class="task-nr" [title]="task.operation.taskType">#{{ taskForm.get('taskID').value }}</span>
      <span *ngIf="task && task.messagesTotal > 0" class="message-icon-header"
        [title]="task.messagesTotal + '/' + (task.messagesTotal - task.messagesRead)">
        <mat-icon [icIcon]="twotoneSpeakerNotes" class="read" *ngIf="task.messagesTotal === task.messagesRead">
        </mat-icon>
        <mat-icon [icIcon]="twotoneSpeakerNotes" class="unread" *ngIf="task.messagesTotal > task.messagesRead">
          {{ task.messagesTotal - task.messagesRead }}</mat-icon>
      </span>
    </h2>
    <h2 *ngIf="taskForm.get('name') && !taskForm.get('name').value" class="headline m-0" fxFlex="auto">
      {{ 'tasker.my-task-detail.new-task' | translate }}
    </h2>
    <div fxLayout="row">
      <h5 *ngIf="taskForm.get('locationName')">
        <a target="_blank" href="http://maps.google.com/?q={{ taskForm.get('address').value }}">{{ taskForm.get('locationName').value }}</a>
      </h5>
      
    </div>
    
  </div>
  <div *ngIf="getAttribute('TicketUrl')" fxFlex style="display: flex; box-sizing: border-box; font-size: 15px; font-weight: normal; color: var(--toolbar-icon-color);justify-content: flex-end;  padding-bottom: 15px;  padding-right: 15px;">
    <a target="_blank" [href]="getAttribute('TicketUrl')"><span><mat-icon [icIcon]="icRoundLink" style="padding-top: 9px; margin-right: 5px;font-size: 20px;"></mat-icon>{{ 'tasker.my-task-detail.ticket-url' | translate }}</span></a>
  </div>

  <button class="text-secondary" (click)="close()" mat-icon-button type="button">
    <mat-icon [icIcon]="icClose"></mat-icon>
  </button>
</div>
